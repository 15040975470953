import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/line-receipt.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="LINEレシートキャンペーン"
		  desc="このサンプルはLINEでレシートキャンペーンを行う方法です。LINE友達を増やしながら新商品の認知拡大や売上アップに繋がり、顧客とのエンゲージメントを深める施策を開催できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/line/instant-win'}
		  type={'Article'}
		  shortName="LINEレシートキャンペーン"
		  createdAt="2021-12-07"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="LINEレシートキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="LINEレシートキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/line',
		  name: 'LINEキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"LINEレシートキャンペーン"}
		description={<>このサンプルはLINEでレシートキャンペーンを行う方法です。<br/>LINE友達が増え新商品の認知拡大や売上アップに繋がり、顧客とのエンゲージメントを深める施策を開催できます。<br/>LINEでキャンペーンを友達に紹介すると当選確率アップなどの方法が効果的です。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.GET_MIDDLE_SPREAD
		  , Constants.CAMPAIGN_EFFECTS.GET_FRIENDS, Constants.CAMPAIGN_EFFECTS.INCREASED_SALES, Constants.CAMPAIGN_EFFECTS.INCREASED_ENGAGEMENT
		  , Constants.CAMPAIGN_EFFECTS.CONNECT_LINE_BUSINESS, Constants.CAMPAIGN_EFFECTS.INCREASED_VISIT_STORE, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION ]}
		plan={'ビジネスプラン'}
		promotionId="ef5cde1c2933e40ecc"
		platformExample={
		  <PlatformExample
			title="その他のLINEキャンペーン"
			types={['lineReferral','twitterToLine','lineInstantWin','lineCoupon']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples